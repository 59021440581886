<template>
  <div class="enterprise" style="float: left;margin-left: 5%;width: 70%;text-align: center;padding: 20px 0 50px 0;">
    <div class="enterprise-title" style="font-size: 24px;margin-bottom: 40px;">
      {{ titlestr }}
      <el-pagination style="float:right;" layout="prev, pager, next" :total="total" :page-size="pageSize"
        :current-page.sync="currentpage" :small="true" @current-change="fanye">
      </el-pagination>
    </div>
    <div v-for="(item, index) in data" :key="index" class="content" @click="jump(item)">
      <div class="imgBox">
        <img :src="item.path" alt="" />
      </div>
      <div class="newsInfo">
        <p class="p1">{{ item.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      get_MoreNews: (state) => {
        return state.commonProblem.get_MoreNews;
      },
    }),
  },
	props: {
		requestType: {
			type: String,
			default: 'MoreNews'
		} 
	},
  data() {
    return {
      data: [],
      titlestr: "党群建设",
      total: 0,
      type: "",
      FindKeyWords: "",
      currentpage: 1,
      pageSize: 5,
    };
  },
  created() {
    // this.getMoreNews(1, 3);
    this.data = [];
    this.titlestr = "";
  },
  methods: {
    jump(item) {
      let routeUrl = this.$router.resolve({
        path: "/news/newsData",
        query: {
          id: item.id,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    fanye(index) {
      this.getMoreNews(index, this.type);
    },
    getload({ index: type, FindKeyWords }) {
      this.currentpage = 1;
      this.type = type;
      this.FindKeyWords = FindKeyWords;
      this.getMoreNews(1, this.type);
    },
    getMoreNews(index, type) {
			let payload = {
          NewsType: type,
          PageSize: this.pageSize,
          PageIndex: index,
      }
			if(this.FindKeyWords){
				payload.FindKeyWords = this.FindKeyWords
				delete payload.NewsType
			}
      this.$store
        .dispatch(`commonProblem/${this.requestType}`, payload)
        .then(() => {
          this.data = this.get_MoreNews.newsdetailVms;
          this.total = this.get_MoreNews.total;
					console.log(type, 'type')
          switch (type) {
            case "0":
              this.titlestr = "热门资讯";
              break;
            case "1":
              this.titlestr = "政策法规";
              break;
            case "2":
              this.titlestr = "常见问题";
              break;
            case "3":
              this.titlestr = "党群建设";
              break;
            case "4":
              this.titlestr = "企业新闻";
              break;
            case "search":
              this.titlestr = "关键词搜索";
              break;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.enterprise-title {
  padding-bottom: 20px;
  border-bottom: 1px dashed rgb(46 196 205); /* no */
}
.content {
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 12px;
  .imgBox {
    width: 200px; /* no */
    height: 150px;
    float: left;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .newsInfo {
    width: calc(100% - 240px); /* no */
    padding-left: 20px;
    float: left;
    .p1 {
      font-size: 22px;
      height: 120px;
      text-align: left;
    }

    .p2 {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 0;
      color: #999999;
    }
  }
}
</style>
